import styled, { css } from 'styled-components';

import { rem, mediaUp } from 'approot/shared/style';

const spacingBase = 16;
export const spacing4 = spacingBase * 0.25;
export const spacing8 = spacingBase * 0.5;
export const spacing12 = spacingBase * 0.75;
export const spacing16 = spacingBase;
export const spacing20 = spacingBase + spacingBase * 0.25;
export const spacing24 = spacingBase + spacingBase * 0.5;
export const spacing32 = spacingBase + spacingBase * 1;
export const spacing40 = spacingBase + spacingBase * 1.5;
export const spacing48 = spacingBase + spacingBase * 2;
export const spacing56 = spacingBase + spacingBase * 2.5;
export const spacing64 = spacingBase + spacingBase * 3;
export const spacing72 = spacingBase + spacingBase * 3.5;
export const spacing80 = spacingBase + spacingBase * 4;

export type ValidSpacings =
  | 0
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 32
  | 40
  | 48
  | 56
  | 64
  | 72
  | 80;

type SpacingContainerProps = {
  hide?: boolean;
};
const CommonContainerCss = css<SpacingContainerProps>`
  ${props => props.hide && 'display: none'};
`;

export const SpacingContainer40 = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing32)};
  ${mediaUp.sm`
    margin-bottom: ${rem(spacing40)};
  `}
`;

export const SpacingContainer24 = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing16)};
  ${mediaUp.sm`
    margin-bottom: ${rem(spacing24)};
  `}
`;

export const SpacingContainer24Fixed = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing24)};
`;

export const SpacingContainer40Fixed = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing40)};
`;

export const SpacingContainer32Fixed = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing32)};
`;

export const SpacingContainer20 = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing16)};
  ${mediaUp.sm`
    margin-bottom: ${rem(spacing20)};
  `}
`;

export const SpacingContainer8Fixed = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing8)};
`;

export const SpacingContainer16Fixed = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing16)};
`;

export const SpacingContainer16 = styled.div<SpacingContainerProps>`
  ${CommonContainerCss}
  margin-bottom: ${rem(spacing8)};
  ${mediaUp.sm`
    margin-bottom: ${rem(spacing16)};
  `}
`;

type SpacingContainerFlexedProps = {
  marginBottom?: number;
};

export const SpacingContainerFlexedStyled = styled.div<
  SpacingContainerFlexedProps & SpacingContainerProps
>`
  ${CommonContainerCss}
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom = spacing24 }) => `${rem(marginBottom)}`};
`;

export const SpacingContainer80 = styled.div<SpacingContainerProps>`
  ${CommonContainerCss};
  margin-bottom: ${rem(spacing72)};
  ${mediaUp.sm`
    margin-bottom: ${rem(spacing80)};
  `}
`;

type SpacingLoadingContainerProps = {
  minHeight: number;
};
export const SpacingLoadingContainer = styled.div<SpacingLoadingContainerProps>`
  min-height: ${props => rem(props.minHeight)};
`;

export type MarginProps = {
  $marginLeft?: ValidSpacings;
  $marginRight?: ValidSpacings;
  $marginTop?: ValidSpacings;
  $marginBottom?: ValidSpacings;
};

export function getMarginValue(
  marginType: 'margin-bottom' | 'margin-top' | 'margin-left' | 'margin-right',
  marginValue?: ValidSpacings,
  marginDefault?: ValidSpacings
) {
  return marginValue !== undefined
    ? `
         ${marginType}: ${marginValue === 0 ? 0 : rem(marginValue)};
      `
    : marginDefault !== undefined
    ? `${marginType}: ${marginDefault === 0 ? 0 : rem(marginDefault)};`
    : '';
}

export function marginCss(defaults?: MarginProps) {
  return css<MarginProps>`
    ${({ $marginBottom }) =>
      getMarginValue('margin-bottom', $marginBottom, defaults?.$marginBottom)}

      ${({ $marginTop }) =>
        getMarginValue('margin-top', $marginTop, defaults?.$marginTop)}

      ${({ $marginLeft }) =>
        getMarginValue('margin-left', $marginLeft, defaults?.$marginLeft)}

      ${({ $marginRight }) =>
        getMarginValue('margin-right', $marginRight, defaults?.$marginRight)}
`;
}
