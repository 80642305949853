import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { rem, mediaUp } from 'approot/shared/style';
import {
  grey3,
  grey5,
  grey6,
  grey7,
  grey9,
  purple2,
  purple6,
  purple7,
} from 'approot/shared/styles/colours.style';
import {
  anchorLinkBase,
  fontWeightMedium,
  fontSize14,
  fontSize16,
} from 'approot/shared/styles/typography.style';
import { spacing8 } from 'approot/shared/styles/spacing.style';

export const CheckboxInputStyled = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 1; // necessary for the hover to work in IE
  cursor: pointer;
`;

const CHECKBOX_SIZE = 16;

type CheckboxLabelProps = {
  disabled?: boolean;
};

export const CheckboxLabelStyled = styled.label<CheckboxLabelProps>`
  display: flex;
  align-items: center;
  color: ${grey9};
  cursor: pointer;
  user-select: none;
  position: relative;
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
  `};
  margin: 0;
`;

export const CheckboxDecorStyled = styled.div`
  border: 1px solid ${grey7};
  border-radius: 4px;
  height: ${rem(CHECKBOX_SIZE)};
  width: ${rem(CHECKBOX_SIZE)};
  margin-right: ${rem(spacing8)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  svg {
    display: none;
  }

  // HOVER STATES
  input:hover + & {
    border: 1px solid ${purple6};
  }

  input:hover:checked + & {
    border: 1px solid ${purple7};
    background-color: ${purple7};
  }

  // DISABLED STATES
  input:disabled + & {
    background-color: ${grey3};
    border: 1px solid ${grey5};
  }

  input:disabled:checked + & {
    background-color: ${grey3};
    border: 1px solid ${grey5};
    svg {
      stroke: ${grey6};
      display: block;
    }
  }

  // ACTIVE STATES
  input:checked + & {
    border: 1px solid ${purple7};
    background-color: ${purple7};

    svg {
      stroke: white;
      display: block;
    }
  }

  // FOCUS STATES
  input:focus + & {
    border: 1px solid ${grey7};
  }

  input:focus:checked:not(:hover) + & {
    border: 1px solid ${purple7};
  }
`;

export const CheckboxDecorFocusStyled = styled.div`
  input:focus ~ & {
    width: ${rem(24)};
    height: ${rem(24)};
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    background-color: ${purple2};
    // centre the focus circle, not using flexbox on the parent as it breaks in IE11
    top: -4px;
    left: -4px;
  }
`;

export const CheckboxContentStyled = styled.span`
  font-size: ${rem(fontSize14)};

  ${mediaUp.md`
    font-size: ${rem(fontSize16)};
  `}
`;

export const CheckboxAnchorStyled = styled(Link)`
  ${anchorLinkBase}

  text-decoration: underline;
  font-weight: ${fontWeightMedium};
  font-size: ${rem(fontSize14)};

  ${mediaUp.md`
    font-size: ${rem(fontSize16)};
  `}
`;
