import { nanoid } from 'nanoid';
import debounce from 'lodash/debounce';
import queryString from 'query-string';

import { performFetch, buildPost } from 'lib/network/http';
import { getUserId } from '../api/auth/auth';
import { getStudentTeacher } from 'approot/student-class-to-do/student-class-to-do.utils';
import { localStorageWrapper } from 'approot/shared/storage';
import { SERVERLESS_API_BASE } from 'webclient.constants';
import {
  PRIVATE_CLASSES,
  PRIVATE_CLASS_BASE,
  PRIVATE_CLASS_TO_DO,
} from 'global/private-routes.constants';
import {
  DataTrackerPayload,
  DataEventAttrs,
  DATA_TRACKER_TYPE_TEACHER,
  DATA_TRACKER_TYPE_GUEST,
  DATA_TRACKER_PAGE_PARAMS,
  DATA_TRACKER_USER_TYPE,
  DATA_TRACKER_CLIENT_ID,
  DATA_TRACKER_SESSION_ID,
  DATA_TRACKER_PRESENTATION_SESSION_ID,
  DATA_TRACKER_PAGE_PATH,
  DATA_TRACKER_HASH_LINK,
  DATA_TRACKER_TYPE_STUDENT,
  DATA_TRACKER_SESSION_TIMEOUT_MILLISECONDS,
  DATA_TRACKER_USER_ID,
  DATA_TRACKER_SUBSCRIPTION_ID,
  DATA_TRACKER_CLASS_USER_ID,
  INQ_DATA_CLIENT_ID,
  INQ_DATA_SESSION_ID,
  INQ_DATA_PRESENTATION_SESSION_ID,
  DATA_TRACKER_INTERNAL,
  DATA_TRACKER_ACCOUNT_TYPE,
  DATA_TRACKER_ACCOUNT_STATUS,
  DATA_TRACKER_ACCOUNT_PLAN,
  DATA_TRACKER_SCHOOL_ROLES,
  DATA_TRACKER_SCHOOL_ID,
  DATA_TRACKER_SCHOOL_STATE,
  DATA_TRACKER_SCHOOL_SECTOR,
  DATA_TRACKER_SCHOOL_TYPE,
  DATA_TRACKER_SCHOOL_COUNTRY,
  DATA_TRACKER_TYPE_OF_SCHOOL,
  DATA_TRACKER_AUTHENTICATION_PROVIDER_ID,
  DATA_TRACKER_SSO_PREFIX,
  DATA_TRACKER_INTERNAL_USER_REGEX,
  DATA_TRACKER_CLASS_ID,
  DATA_TRACKER_SCREEN_RESOLUTION,
  DATA_TRACKER_REFERRER,
  DATA_TRACKER_USER_AGENT,
  DATA_TRACKER_USER_POSITION,
  DATA_TRACKER_ACCOUNT_FREE_TRIAL,
  DATA_TRACKER_BILLING_ACCOUNT_NAME,
  DATA_TRACKER_APPLICATION_VERSION,
  DATA_TRACKER_ENCODE_SINGLE_QUOTE,
  DATA_TRACKER_ENCODE_SPECIAL_CHARS,
} from './data-tracker.constants';
import {
  sanitizeQueryParams,
  formatTimestampValues,
  mergePageParamValues,
  convertPageParamsToArrays,
  createGoogleAnalyticsDataEvent,
  downcaseAndDedupePageParams,
  dataTrackerEncodeValue,
} from './data-tracker.utils';
import { InqApolloClient } from '../../../apollo';
import { IS_SIGNED_IN, GET_USER } from 'approot/shared/user/user-data.graphql';
import { GetUser } from 'approot/shared/user/__generated__/GetUser';
import {
  ACCOUNT_STATUS_INACTIVE,
  ACCOUNT_STATUS_DELETED,
  ACCOUNT_SCHOOL,
} from 'global/constants';
import { isDevEnv } from 'approot/shared/layout/header/header.utils';
import { getCurrentClass } from 'approot/classes/classes.apollo';
import {
  PUBLIC_AUDIO_BASE,
  PUBLIC_GUIDED_RESEARCH_BASE,
  PUBLIC_INTERACTIVE_BASE,
  PUBLIC_VIDEO_BASE,
} from 'global/public-routes.constants';

class DataTracker {
  private timeoutId: number | null = null;
  private client: InqApolloClient | null = null;

  start() {
    this.generateSessionId();
    this.generateClientId();
    document.addEventListener('mousemove', this.updateTimer, false);
    document.addEventListener('mousedown', this.updateTimer, false);
    document.addEventListener('keypress', this.updateTimer, false);
    document.addEventListener('touchmove', this.updateTimer, false);
  }

  tick() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.timeoutId = window.setTimeout(() => {
      this.generateSessionId(true);
      this.updateTimer();
    }, DATA_TRACKER_SESSION_TIMEOUT_MILLISECONDS);
  }

  updateTimer = debounce(() => {
    this.tick();
  }, 200);

  stop() {
    document.removeEventListener('mousemove', this.updateTimer, false);
    document.removeEventListener('mousedown', this.updateTimer, false);
    document.removeEventListener('keypress', this.updateTimer, false);
    document.removeEventListener('touchmove', this.updateTimer, false);
  }

  generateClientId() {
    // client id which is a guid in localStorage
    const item = localStorageWrapper.getItem(INQ_DATA_CLIENT_ID);
    if (!item) {
      const dataTrackingId = nanoid();
      localStorageWrapper.setItem(INQ_DATA_CLIENT_ID, dataTrackingId);
    }
  }

  generateSessionId(forceNewID: boolean = false) {
    const item = localStorageWrapper.getItem(INQ_DATA_SESSION_ID);
    if (forceNewID || !item) {
      localStorageWrapper.setItem(INQ_DATA_SESSION_ID, nanoid());
    }
  }

  getSessionId() {
    return localStorageWrapper.getItem(INQ_DATA_SESSION_ID) || '';
  }

  getClientId() {
    return localStorageWrapper.getItem(INQ_DATA_CLIENT_ID) || '';
  }

  getPresentationSessonId() {
    return (
      localStorageWrapper.getItem(INQ_DATA_PRESENTATION_SESSION_ID) || null
    );
  }

  setApolloClient(client: InqApolloClient) {
    this.client = client;
  }

  getPayload(dataEvent: DataEventAttrs): DataTrackerPayload {
    const location = window.location;

    const sanitizedParams = sanitizeQueryParams(
      queryString.parse(location.search)
    );

    const payload: DataTrackerPayload = {
      ...dataEvent,
      ...formatTimestampValues(),
      [DATA_TRACKER_PAGE_PATH]: dataTrackerEncodeValue({
        value: location.pathname,
        encoderFunction: DATA_TRACKER_ENCODE_SINGLE_QUOTE,
      }),
      [DATA_TRACKER_HASH_LINK]: dataTrackerEncodeValue({
        value: dataEvent[DATA_TRACKER_HASH_LINK] || location.hash,
        encoderFunction: DATA_TRACKER_ENCODE_SPECIAL_CHARS,
      }),
      [DATA_TRACKER_SESSION_ID]: this.getSessionId(),
      [DATA_TRACKER_CLIENT_ID]: this.getClientId(),
      [DATA_TRACKER_USER_TYPE]: DATA_TRACKER_TYPE_GUEST,
      [DATA_TRACKER_INTERNAL]: false,
      [DATA_TRACKER_PAGE_PARAMS]: JSON.stringify(
        downcaseAndDedupePageParams(
          mergePageParamValues(
            convertPageParamsToArrays(sanitizedParams),
            convertPageParamsToArrays(dataEvent[DATA_TRACKER_PAGE_PARAMS])
          )
        )
      ),
      [DATA_TRACKER_SCREEN_RESOLUTION]: `${window.screen.width}x${window.screen.height}`,
      [DATA_TRACKER_REFERRER]: dataTrackerEncodeValue({
        value: document.referrer || 'direct',
        encoderFunction: DATA_TRACKER_ENCODE_SINGLE_QUOTE,
      }),
      [DATA_TRACKER_USER_AGENT]: dataTrackerEncodeValue({
        value: window.navigator.userAgent,
        encoderFunction: DATA_TRACKER_ENCODE_SPECIAL_CHARS,
      }),
      [DATA_TRACKER_BILLING_ACCOUNT_NAME]: null,
      [DATA_TRACKER_APPLICATION_VERSION]:
        process.env.REACT_APP_API_VERSION || '',
      [DATA_TRACKER_PRESENTATION_SESSION_ID]: this.getPresentationSessonId(),
    };

    const userId = getUserId();
    const signedInData = this.client?.getClient().readQuery({
      query: IS_SIGNED_IN,
    });

    if (
      signedInData?.isStudentSignedIn &&
      [
        PRIVATE_CLASS_BASE,
        PUBLIC_VIDEO_BASE,
        PUBLIC_AUDIO_BASE,
        PUBLIC_INTERACTIVE_BASE,
        PUBLIC_GUIDED_RESEARCH_BASE,
      ].some(page => location.pathname.includes(page)) &&
      !location.pathname.includes(PRIVATE_CLASSES) &&
      !location.pathname.endsWith(PRIVATE_CLASS_TO_DO)
    ) {
      payload[DATA_TRACKER_CLASS_USER_ID] = getStudentTeacher()?.id;
      payload[DATA_TRACKER_USER_TYPE] = DATA_TRACKER_TYPE_STUDENT;
    } else if (userId && signedInData?.isSignedIn) {
      const userData = this.client?.getClient().readQuery<GetUser>({
        query: GET_USER,
      });

      payload[DATA_TRACKER_USER_ID] = userId;
      payload[DATA_TRACKER_USER_TYPE] = DATA_TRACKER_TYPE_TEACHER;
      payload[DATA_TRACKER_BILLING_ACCOUNT_NAME] = userData?.user
        ?.isFedGrantUser
        ? 'Federal Grant 2023'
        : null;
      payload[DATA_TRACKER_USER_POSITION] = userData?.user?.position;
      payload[DATA_TRACKER_INTERNAL] = DATA_TRACKER_INTERNAL_USER_REGEX.test(
        userData?.user?.email || ''
      );
      payload[DATA_TRACKER_ACCOUNT_TYPE] =
        userData?.user?.accountType === ACCOUNT_SCHOOL
          ? 'school'
          : 'individual';
      payload[DATA_TRACKER_ACCOUNT_STATUS] = [
        ACCOUNT_STATUS_INACTIVE,
        ACCOUNT_STATUS_DELETED,
      ].includes(userData?.user?.accountStatus || '')
        ? 'inactive'
        : 'active';
      payload[DATA_TRACKER_ACCOUNT_FREE_TRIAL] = userData?.user?.isInTrial;

      if (userData?.user?.subscriptions?.[0]) {
        payload[DATA_TRACKER_ACCOUNT_PLAN] =
          userData.user.subscriptions[0].plan;
        payload[DATA_TRACKER_SUBSCRIPTION_ID] =
          userData.user.subscriptions[0].id;
      }

      payload[DATA_TRACKER_SCHOOL_ID] = userData?.user?.school.id;
      payload[DATA_TRACKER_SCHOOL_STATE] = userData?.user?.school.state;
      payload[DATA_TRACKER_SCHOOL_TYPE] = userData?.user?.school.schoolType;
      payload[DATA_TRACKER_SCHOOL_SECTOR] = userData?.user?.school.schoolSector;
      payload[DATA_TRACKER_SCHOOL_COUNTRY] = userData?.user?.school.country;
      payload[DATA_TRACKER_TYPE_OF_SCHOOL] =
        userData?.user?.school.typeOfSchool;
      payload[DATA_TRACKER_SCHOOL_ROLES] = userData?.user?.schoolRoles.filter(
        role => role !== 'billing'
      );
      payload[DATA_TRACKER_AUTHENTICATION_PROVIDER_ID] = userData?.user
        ?.sessionAuthProvider
        ? `${DATA_TRACKER_SSO_PREFIX}${userData?.user?.sessionAuthProvider}`
        : 'inquisitive';

      if (getCurrentClass()) {
        payload[DATA_TRACKER_CLASS_ID] = getCurrentClass()?.id;
      }
    }

    return payload;
  }

  push(data: DataEventAttrs) {
    const payload = this.getPayload(data);

    if (isDevEnv() && process.env.NODE_ENV !== 'test') {
      console.debug('I', payload);
    }

    if (
      (window.location.hostname === 'localhost' &&
        process.env.REACT_APP_DATA_TRACKING_DEBUG === 'true') ||
      window.location.hostname === 'dubbo.inquisitive.com' ||
      window.location.hostname === 'www.inquisitive.com'
    ) {
      performFetch({
        args: buildPost({
          path: `${SERVERLESS_API_BASE}/kinesis/click-stream`,
          postJSON: payload,
        }),
        sourceFile: 'data-tracker.tsx',
      });
    }

    return createGoogleAnalyticsDataEvent(data);
  }
}

export const dataTracker = new DataTracker();
