import { gql } from '@apollo/client';

import { SubjectFragment } from 'approot/shared/graphql/startup-data/startup-data.graphql';

const TermFragment = gql`
  fragment TermFragment on UserTerm {
    id
    calendarYear
    start
    end
    term
  }
`;

const classTeacherFragment = gql`
  fragment ClassTeacherFragment on ClassTeacher {
    id
    userId
    firstName
    lastName
    emeji
    email
    classPermission
    hasActiveAccount
    invitedBy
  }
`;

export const classFragment = gql`
  fragment ClassFragment on UserClass {
    id
    name
    colour
    curriculums
    classToDoPasscode
    classToDoUrl
    classToDoQRCodeUrl
    classToDoInstructions
    createdAt
    archivedAt
    canBeArchived
    lastMarkedAsUsed
    teachingYear
    isDefaultClass
    isFromCurrentTeachingYear
    terms {
      ...TermFragment
    }
    planner {
      id
    }
    years {
      id
      name
      label
    }
    subjects {
      ...SubjectFragment
    }
    mainTeacher {
      ...ClassTeacherFragment
    }
    classTeachers {
      ...ClassTeacherFragment
    }
  }
  ${SubjectFragment}
  ${TermFragment}
  ${classTeacherFragment}
`;

export const GET_CLASS_BY_ID = gql`
  query GetClassById($input: GetClassByIdInput!) {
    getClassById(input: $input) {
      ...ClassFragment
    }
  }
  ${classFragment}
`;

export const CREATE_CLASS = gql`
  mutation CreateClass($input: CreateClassInput!) {
    createClass(input: $input) {
      ...ClassFragment
    }
  }
  ${classFragment}
`;

export const UPDATE_CLASS = gql`
  mutation UpdateClass($input: UpdateClassInput!) {
    updateClass(input: $input) {
      ...ClassFragment
    }
  }
  ${classFragment}
`;

export const SET_CLASS_DEFAULT = gql`
  mutation SetClassDefault($input: SetClassDefaultByIdInput!) {
    setClassDefault(input: $input) {
      id
      isDefaultClass
    }
  }
`;

export const ARCHIVE_CLASS = gql`
  mutation ArchiveClass($input: ArchiveClassByIdInput!) {
    archiveClass(input: $input) {
      id
      defaultClassInitialisedAt
      userClasses {
        ...ClassFragment
      }
    }
  }
  ${classFragment}
`;

export const UNARCHIVE_CLASS = gql`
  mutation UnarchiveClass($input: UnarchiveClassByIdInput!) {
    unarchiveClass(input: $input) {
      ...ClassFragment
    }
  }
  ${classFragment}
`;

export const DUPLICATE_CLASS = gql`
  mutation DuplicateClass($input: DuplicateClassByIdInput!) {
    duplicateClass(input: $input) {
      ...ClassFragment
    }
  }
  ${classFragment}
`;

export const MARK_CLASS_AS_USED = gql`
  mutation MarkClassAsUsed($input: MarkClassAsUsedInput!) {
    markClassAsUsed(input: $input) {
      ...ClassFragment
    }
  }
  ${classFragment}
`;

export const GET_CREATE_CLASS_REMINDER = gql`
  query GetCreateClassReminder {
    user {
      id
      permission {
        showCreateClassReminder
      }
    }
  }
`;

export const ADD_TEACHERS_TO_CLASS = gql`
  mutation AddTeachersToClass($input: AddTeachersToClassInput!) {
    addTeachersToClass(input: $input) {
      ...ClassTeacherFragment
    }
  }
  ${classTeacherFragment}
`;

export const SHARE_CLASS_TEACHERS_EMAIL_VALIDATION = gql`
  query ShareClassTeachersEmailValidation($input: EmailValidationInput!) {
    shareClassTeachersEmailValidation(input: $input) {
      id
      canAddToClass
    }
  }
`;

export const REMOVE_TEACHER_FROM_CLASS = gql`
  mutation RemoveTeacherFromClass($input: RemoveTeacherFromClassInput!) {
    removeTeacherFromClass(input: $input)
  }
`;

export const USER_LEAVES_CLASS = gql`
  mutation LeaveClass($input: UserLeavesClassInput!) {
    userLeavesClass(input: $input) {
      id
    }
  }
`;
