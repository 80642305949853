import styled from 'styled-components';

import { rem } from 'approot/shared/style';
import { spacing16 } from 'approot/shared/styles/spacing.style';
import { borderRadius8 } from 'approot/shared/styles/borders.style';

type FullWidthButtonsProps = {
  marginBottom?: number;
};

export const FullWidthButtonsStyled = styled.div<FullWidthButtonsProps>`
  width: 100%;

  button,
  a {
    width: 100%;
    margin-bottom: ${({ marginBottom = spacing16 }) => rem(marginBottom)};
  }
`;

export const ButtonGroup = styled.div`
  button {
    border-radius: 0;
    position: relative;
    margin-right: -1px;
    &:focus {
      z-index: 2;
    }
  }

  button:first-child,
  div:first-child button {
    border-radius: ${rem(borderRadius8)} 0 0 ${rem(borderRadius8)};
  }

  button:last-child,
  div:last-child button {
    border-radius: 0 ${rem(borderRadius8)} ${rem(borderRadius8)} 0;
  }
`;
