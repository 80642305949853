import queryString from 'query-string';
import { Route, Location } from 'react-router-dom';

import { getUserClassPath } from 'approot/classes/classes.apollo';
import { PRIVATE_ACCOUNT_PROMO_CODE_QUERY_STRING } from 'global/private-routes.constants';
import { RouteConfig } from 'lib/router/router.constants';

export function getLocationParams(location: Location) {
  return queryString.parse(location.search);
}

export function pathWithParentId(
  path: string,
  parentId: string | number | undefined
) {
  return `${getUserClassPath(path)}${parentId ? `?parentId=${parentId}` : ''}`;
}

export function getPromoCodeQueryString(code?: string) {
  return code ? `${PRIVATE_ACCOUNT_PROMO_CODE_QUERY_STRING}${code}` : '';
}

export const renderMultipleRoutes = ({
  path,
  component: Element,
}: RouteConfig) => {
  return Array.isArray(path) ? (
    path.map(p => <Route key={p} path={p} element={<Element />} />)
  ) : (
    <Route key={path} path={path} element={<Element />} />
  );
};
