import styled, { css } from 'styled-components';

import { rem, mediaUp } from 'approot/shared/style';
import {
  spacing4,
  spacing8,
  spacing16,
} from 'approot/shared/styles/spacing.style';
import {
  fontSize12,
  fontSize14,
  font16,
  fontSize16LineHeight,
  fontFamilyBase,
  fontWeightMedium,
  font12,
} from 'approot/shared/styles/typography.style';
import {
  grey10,
  grey3,
  grey9,
  SubjectColours,
} from 'approot/shared/styles/colours.style';
import { standardFocusState } from 'approot/shared/styles/borders.style';

type BadgeColourProps = {
  $colour?: string;
};

const subjectColoursCss = css<BadgeColourProps>`
  ${({ $colour }) =>
    $colour &&
    `
      background-color: ${SubjectColours[$colour]?.[1]};
      color: ${SubjectColours[$colour]?.[9]};
    `}
`;

type SubjectBadgeProps = BadgeColourProps & {
  $isSmall?: boolean;
};

export const SubjectBadgeStyled = styled.div<SubjectBadgeProps>`
  ${font16}

  background-color: ${grey3};
  border-radius: ${rem(24)};
  color: ${grey9};
  display: inline-block;
  padding: ${rem(spacing4)} ${rem(spacing16)};

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      ${font12}
      padding: ${rem(2)} ${rem(spacing8)};
  `}

  ${subjectColoursCss};
`;

export const BADGE_GREEN = 'green';

const getBadgeBackgroundColor = ($colour?: string) => {
  if ($colour === BADGE_GREEN) {
    return SubjectColours.green[1];
  }
  return $colour || grey3;
};

const getBadgeColor = ($colour?: string) => {
  if ($colour === BADGE_GREEN) {
    return SubjectColours.green[9];
  }
  return $colour ? grey10 : grey9;
};

export const BadgeStyled = styled.div<BadgeColourProps>`
  ${font16}
  background-color: ${({ $colour }) => getBadgeBackgroundColor($colour)};
  border-radius: ${rem(48)};
  color: ${({ $colour }) => getBadgeColor($colour)};
  display: inline-block;
  padding: ${rem(spacing4)} ${rem(spacing16)};
  text-align: center;
`;

const roundBadgeBase = css`
  font-size: ${rem(fontSize14)};
  line-height: ${rem(32)};
  height: ${rem(32)};
  min-width: ${rem(32)};
`;

type BadgeRoundProps = BadgeColourProps & {
  isResponsive: boolean;
} & JSX.IntrinsicElements['div'];
export const BadgeRoundStyled = styled.div<BadgeRoundProps>`
  background-color: ${grey3};
  border-radius: ${rem(32)};
  color: ${grey9};
  display: inline-block;
  font-family: ${fontFamilyBase};
  font-weight: ${fontWeightMedium};
  font-size: ${rem(fontSize12)};
  line-height: ${rem(fontSize16LineHeight)};
  height: ${rem(24)};
  min-width: ${rem(24)};
  padding: 0 ${rem(spacing4)};
  text-align: center;

  ${mediaUp.sm<BadgeRoundProps>`
    ${({ isResponsive }) => isResponsive && roundBadgeBase}
  `}

  ${standardFocusState};
  ${subjectColoursCss};
`;

export const BadgeSmallStyled = styled(BadgeStyled)`
  ${font12}
  ${subjectColoursCss}
  display: inline-block;
  padding: ${rem(2)} ${rem(spacing8)};
`;
