import { gql } from '@apollo/client';

export const LessonMaterialFragment = gql`
  fragment LessonMaterialFragment on LessonMaterial {
    id
    materialId
    groupType
    name
    numberOfItems
    numberInGroup
    note
    alternativeMaterials {
      id
      name
    }
    isOptional
  }
`;
