import styled from 'styled-components';

import { hexColourToRGBArray } from 'lib/strings/strings.colour';
import {
  spacing12,
  spacing24,
  spacing32,
} from 'approot/shared/styles/spacing.style';
import { rem } from 'approot/shared/style';
import { grey4, purple1, purple9 } from './colours.style';
import { font14 } from './typography.style';

type HrProps = {
  marginTop?: number;
  marginBottom?: number;
};

export const HrStyled = styled.hr.attrs({
  'aria-hidden': true,
})<HrProps>`
  border-color: ${grey4};
  margin-top: ${({ marginTop = spacing24 }) => rem(marginTop)};
  margin-bottom: ${({ marginBottom = spacing32 }) => rem(marginBottom)};
`;

// Regular ease-in-out
export const getEasedInOutGradientStops = (
  colour: string,
  startPercent: number,
  endPercent: number
) => {
  const rgb = hexColourToRGBArray(colour).join(', ');
  const factor = (endPercent - startPercent) / 100;
  const output = `
      rgb(${rgb}) 0%,
      rgba(${rgb}, 0.987) ${(factor * 8.1 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.951) ${(factor * 15.5 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.896) ${(factor * 22.5 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.825) ${(factor * 29 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.741) ${(factor * 35.3 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.648) ${(factor * 41.2 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.55) ${(factor * 47.1 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.45) ${(factor * 52.9 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.352) ${(factor * 58.8 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.259) ${(factor * 64.7 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.175) ${(factor * 71 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.104) ${(factor * 77.5 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.049) ${(factor * 84.5 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.013) ${(factor * 91.9 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0) ${(factor * 100 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0) 100%
   `;
  return output;
};

// Tiny ease in with long eased out trail
export const getEasedOutGradientStops = (
  colour: string,
  startPercent: number,
  endPercent: number
) => {
  const rgb = hexColourToRGBArray(colour).join(', ');
  const factor = (endPercent - startPercent) / 100;
  const output = `
      rgb(${rgb}) 0%,
      rgba(${rgb}, 0.987) ${(factor * 4.5 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.951) ${(factor * 8.6 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.896) ${(factor * 12.4 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.825) ${(factor * 16.1 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.741) ${(factor * 19.9 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.648) ${(factor * 24 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.55) ${(factor * 28.4 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.45) ${(factor * 33.5 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.352) ${(factor * 39.3 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.259) ${(factor * 46.1 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.175) ${(factor * 53.9 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.104) ${(factor * 63.1 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.049) ${(factor * 73.7 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0.013) ${(factor * 86 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0) ${(factor * 100 + startPercent).toFixed(2)}%,
      rgba(${rgb}, 0) 100%
   `;
  return output;
};

export const InlineBubble = styled.span`
  ${font14}

  display: inline-block;
  color: ${purple9};
  background-color: ${purple1};
  border-radius: ${rem(20)};
  height: ${rem(26)};
  padding: ${rem(2)} ${rem(spacing12)};
  white-space: nowrap;
`;
