import { useState, useEffect, useCallback } from 'react';
import { css, Interpolation } from 'styled-components';
import { Styles, NoInfer } from 'styled-components/dist/types';

export const pixelsPerRem = 16;

// high contrast gradient
export const gradientStart = '#8493c9';
export const gradientMiddle = '#9e8bca';
export const gradientEnd = '#ad88b9';
export const gradient = `linear-gradient(90deg, ${gradientStart} 0%, ${gradientMiddle} 50%, ${gradientEnd} 100%);`;

export const rem = (val: number | string): string =>
  `${parseFloat(val.toString()) / pixelsPerRem}rem`;

export const centeredContainerWidth = rem(768);
export const narrowContainerWidth = rem(570);

export const BREAKPOINT_SM = 576;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;
export const BREAKPOINT_XL = 1200;

type MediaSizeObject = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

export const mediaSizes: MediaSizeObject = {
  xs: 0,
  sm: BREAKPOINT_SM,
  md: BREAKPOINT_MD,
  lg: BREAKPOINT_LG,
  xl: BREAKPOINT_XL,
};
const mediaDownSizes: MediaSizeObject = {
  xs: BREAKPOINT_SM,
  sm: BREAKPOINT_MD,
  md: BREAKPOINT_LG,
  lg: BREAKPOINT_XL,
  xl: 1200,
};

export const mediaUp = {
  xs: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (min-width: ${mediaSizes.xs}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  sm: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (min-width: ${mediaSizes.sm}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  md: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (min-width: ${mediaSizes.md}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  lg: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (min-width: ${mediaSizes.lg}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  xl: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (min-width: ${mediaSizes.xl}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
};

export const mediaDown = {
  xs: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (max-width: ${mediaDownSizes.xs}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  sm: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (max-width: ${mediaDownSizes.sm}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  md: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (max-width: ${mediaDownSizes.md}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  lg: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (max-width: ${mediaDownSizes.lg}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
  xl: <Props extends object>(
    styles: Styles<NoInfer<Props>>,
    ...args: Interpolation<NoInfer<Props>>[]
  ) => css<Props>`
    @media (max-width: ${mediaDownSizes.xl}px) {
      ${css<Props>(styles, ...args)};
    }
  `,
};

export const transition = (transition: string) => {
  return css`
    transition: ${transition};
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  `;
};

export const hiddenPrint = `
  @media print {
    display: none !important;
  }
`;

export const printOnly = `
  @media print {
    display: block !important;
  }
`;

export const mediaHover = (
  args: TemplateStringsArray,
  ...interpolations: Interpolation<any>[]
) => css`
  @media (hover: hover) {
    ${css(args, ...interpolations)}
  }
`;

export const MEDIA_SM: MinMediaQuery = `(min-width: ${BREAKPOINT_SM}px)`;
export const MEDIA_MD: MinMediaQuery = `(min-width: ${BREAKPOINT_MD}px)`;
export const MEDIA_LG: MinMediaQuery = `(min-width: ${BREAKPOINT_LG}px)`;
export const MEDIA_XL: MinMediaQuery = `(min-width: ${BREAKPOINT_XL}px)`;

const getMinMediaQuery = (breakpoint: number) =>
  `(min-width: ${breakpoint}px)` as const;

export type MinMediaQuery = ReturnType<typeof getMinMediaQuery>;

export const useMediaQuery = (mediaQuery: MinMediaQuery) => {
  const [isMq, setIsMq] = useState<boolean>(
    window.matchMedia(mediaQuery).matches
  );

  const onResize = useCallback(
    (e: MediaQueryListEvent) => setIsMq(e.matches),
    []
  );

  useEffect(() => {
    const mql = window.matchMedia(mediaQuery);
    mql.addListener(onResize);

    return () => {
      mql.removeListener(onResize);
    };
    //eslint-disable-next-line
  }, []);

  return isMq;
};
