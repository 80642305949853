import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';

import routes from 'lib/router/routes';
import { useRegionData } from 'approot/shared/region/region.apollo';
import { renderMultipleRoutes } from 'lib/router/router.util';
import Startup, { ScrollMemoryMonitor, SessionTakenMonitor } from 'startup';
import { SnackbarNotificationContextProvider } from 'approot/shared/snackbar-notification/snackbar-notification.context';
import GlobalNotifications from 'approot/global-notifications/global-notifications';
import GlobalLaunchDarkly from 'approot/global-launch-darkly/global-launch-darkly';
import GlobalSnackBarNotification from 'approot/shared/snackbar-notification/snackbar-notification';
import { browserUnsupported } from 'polyfills.utils';

const OldBrowserPage = lazy(() => import('approot/errors/old-browser-page'));

export const SwitchRouter = () => {
  const data = useRegionData();

  return (
    <BrowserRouter
      basename={
        window.location.pathname.startsWith(`/${data.region}`)
          ? `/${data.region}`
          : undefined
      }
    >
      {browserUnsupported() ? (
        <Suspense fallback={<div />}>
          <OldBrowserPage />
        </Suspense>
      ) : (
        <Startup>
          <SnackbarNotificationContextProvider>
            <GlobalSnackBarNotification />
            <GlobalNotifications>
              <GlobalLaunchDarkly />
              <ScrollMemoryMonitor />
              <SessionTakenMonitor />
              <Routes>
                {routes.map(route =>
                  renderMultipleRoutes({
                    path: route.path,
                    component: route.component,
                  })
                )}
              </Routes>
            </GlobalNotifications>
          </SnackbarNotificationContextProvider>
        </Startup>
      )}
    </BrowserRouter>
  );
};
