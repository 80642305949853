import {
  clearAnalyticsDataLayer,
  updateAnalyticsDataLayer,
} from 'approot/shared/analytics/data-layer';
import {
  E_CLICKS_LOGGED_IN,
  pushEClicksDataEvent,
} from 'approot/shared/data-tracker/e-clicks-data-event';
import { pushDataTrackerEvent } from 'approot/shared/data-tracker/data-tracker-push';
import { DataTrackerEventData } from 'approot/shared/data-tracker/data-tracker.constants';
import {
  IS_SIGNED_IN,
  SIGN_IN_REDIRECTION_PATH,
  SKIP_CHOOSE_CLASS_REDIRECTION_PATH,
  GET_USER,
} from 'approot/shared/user/user-data.graphql';
import { UserFragment } from 'approot/shared/user/__generated__/UserFragment';
import { isAccountInactive } from 'approot/shared/user/user-data';
import { setCurrentClass } from 'approot/classes/classes.apollo';
import { openInactiveAccountModal } from 'approot/global-notifications/global-notification.utils';
import {
  EVENT_ACTION_SIGN_IN,
  EVENT_ACTION_SIGN_OUT,
  EVENT_CATEGORY_AUTHENTICATION,
  EVENT_TARGET_TYPE_TEACHER,
} from 'approot/shared/data-tracker/data-events.constants';
import {
  setUserData,
  clearUserData,
  updateAlreadySignedInOnce,
  clearHistoryState,
  getUserId,
} from 'approot/shared/api/auth/auth';
import {
  setStudentUserData,
  clearStudentUserData,
} from 'approot/shared/api/auth/student-user-auth';
import { StorageUserData } from 'approot/shared/api/auth/auth.constants';
import { PRIVATE_ACCOUNT_REACTIVATE } from 'global/private-routes.constants';
import { client as apolloClient } from '../../../apollo';
import { RememberType } from './signin.constants';
import { StudentFragment } from 'approot/students/__generated__/StudentFragment';
import {
  endIntercomSession,
  startAuthenticatedIntercomSession,
  startUnauthenticatedIntercomSession,
} from 'lib/intercom/intercom';
import { wsClient } from 'lib/ws/ws-client';

export function handleSignInData(
  remember: boolean,
  token: string,
  user: UserFragment,
  sessionAuthProvider?: string | null
) {
  const rememberType = remember
    ? RememberType.LocalStorage
    : RememberType.SessionStorage;
  const userId = user.id;

  const userWithSessionAuth = {
    ...user,
    sessionAuthProvider: sessionAuthProvider || null,
  };

  const data: StorageUserData = {
    token,
    user: userWithSessionAuth,
  };

  // add user data to storage
  if (
    rememberType === RememberType.LocalStorage ||
    rememberType === RememberType.SessionStorage
  ) {
    setUserData(data, rememberType);
  }

  apolloClient.getClient().writeQuery({
    query: GET_USER,
    data: {
      user: userWithSessionAuth,
    },
  });

  updateSignInStateInCache({
    isSignedIn: true,
    isAlreadySignedInOnce: true,
    isStudentSignedIn: false,
  });

  updateAlreadySignedInOnce();
  updateAnalyticsDataLayer(user);

  if (userId) {
    if (
      isAccountInactive(user.accountStatus) &&
      user.hasStrongPassword &&
      window.location.pathname !== PRIVATE_ACCOUNT_REACTIVATE
    ) {
      openInactiveAccountModal();
    }
  }

  startAuthenticatedIntercomSession({
    id: user.id,
    email: user.email,
    hash: user.intercomHash,
    schoolId: user.school.id,
    schoolName: user.school.name,
    accountType: user.accountType,
  });
}

export function handleStudentUserSignin(
  token: string,
  studentUser: StudentFragment
) {
  setStudentUserData({
    token,
    studentUser,
  });
}

export function signOut() {
  pushDataTrackerEvent(EVENT_CATEGORY_AUTHENTICATION, EVENT_ACTION_SIGN_OUT, {
    target_type: EVENT_TARGET_TYPE_TEACHER,
    target_identifier: getUserId(),
  });

  clearUserData();

  // sessionStorage can contain data queried by a logged in user
  // if location.state is utilised i.e. in the add teacher purchase flow
  clearHistoryState();

  clearAnalyticsDataLayer();

  // if there is an active websocket client close it
  if (wsClient) {
    wsClient.dispose();
  }

  setCurrentClass(undefined);

  // this needs to be done first so resetStore re-queries
  // without authorization headers
  updateSignInStateInCache({
    isSignedIn: false,
    isAlreadySignedInOnce: true,
    isStudentSignedIn: false,
  });
  apolloClient?.getClient()?.resetStore();

  endIntercomSession();
  startUnauthenticatedIntercomSession();
}

export function studentSignOut(callback?: () => void) {
  clearStudentUserData();
  apolloClient
    ?.getClient()
    ?.resetStore()
    .then(callback);
}

type ClientSignIn = {
  isSignedIn: boolean;
  isAlreadySignedInOnce: boolean;
  isStudentSignedIn: boolean;
};

export function updateSignInStateInCache({
  isSignedIn,
  isAlreadySignedInOnce,
  isStudentSignedIn = false,
}: ClientSignIn) {
  apolloClient.getClient().writeQuery({
    query: IS_SIGNED_IN,
    data: {
      isSignedIn,
      isAlreadySignedInOnce,
      isStudentSignedIn,
    },
  });
}

export function setSigninRedirectionPath(redirectPath: string = '') {
  apolloClient.getClient().writeQuery({
    query: SIGN_IN_REDIRECTION_PATH,
    data: {
      signInRedirectionPath: redirectPath
        ? redirectPath
        : `${window.location.pathname}${window.location.search}`,
    },
  });
}

export function setSkipChooseClassPath(redirectPath: string = '') {
  apolloClient.getClient().writeQuery({
    query: SKIP_CHOOSE_CLASS_REDIRECTION_PATH,
    data: {
      skipChooseClassRedirectionPath: redirectPath
        ? redirectPath
        : window.location.pathname,
    },
  });
}

export function pushSignInToGtm(eventData: DataTrackerEventData) {
  pushDataTrackerEvent(
    EVENT_CATEGORY_AUTHENTICATION,
    EVENT_ACTION_SIGN_IN,
    eventData
  );
}

export function pushSignInEClicks(userId: number, position: string) {
  pushEClicksDataEvent({
    event: 'login',
    status: E_CLICKS_LOGGED_IN,
    userId,
    position,
  });
}
