import { gql } from '@apollo/client';

export const studentFragment = gql`
  fragment StudentFragment on Student {
    id
    email
    firstName
    lastName
    decryptedPassword
    currentClasses {
      id
      className
      classTeacherDisplayName
      classToDoUrl
      teacherEmeji
      colour
    }
  }
`;

export const GET_CLASS_STUDENTS = gql`
  query GetClassStudents($input: GetClassStudentsInput!) {
    getClassStudents(input: $input) {
      ... on Student {
        ...StudentFragment
      }
    }
  }
  ${studentFragment}
`;

export const ADD_STUDENTS_TO_CLASS = gql`
  mutation AddStudentsToClass($input: AddStudentsToClassInput!) {
    addStudentsToClass(input: $input) {
      students {
        ...StudentFragment
      }
      invalidEmails
    }
  }
  ${studentFragment}
`;

export const IMPORT_STUDENTS_FROM_GOOGLE_CLASSROOM = gql`
  mutation ImportStudentsFromGoogleClassroom(
    $input: ImportStudentsFromGoogleClassroomInput!
  ) {
    importStudentsFromGoogleClassroom(input: $input) {
      ... on Student {
        ...StudentFragment
      }
    }
  }
  ${studentFragment}
`;

export const REMOVE_STUDENTS_FROM_CLASS = gql`
  mutation RemoveStudentsFromClass($input: RemoveStudentsFromClassInput!) {
    removeStudentsFromClass(input: $input) {
      ... on Student {
        ...StudentFragment
      }
    }
  }
  ${studentFragment}
`;

export const COPY_STUDENTS_TO_CLASS = gql`
  mutation CopyStudentsToClass($input: CopyStudentsToClassInput!) {
    copyStudentsToClass(input: $input)
  }
`;

export const GET_STUDENT = gql`
  query GetStudentById($input: GetStudentByIdInput) {
    getStudentById(input: $input) {
      ...StudentFragment
    }
  }
  ${studentFragment}
`;

export const GET_GOOGLE_CLASSROOM_AUTH_URL = gql`
  query GetGoogleClassroomAuthUrl {
    getGoogleClassroomAuthUrl
  }
`;

export const GET_GOOGLE_CLASSROOM_LIST = gql`
  query GetGoogleClassroomList {
    getGoogleClassroomList {
      id
      name
    }
  }
`;

export const UPDATE_STUDENT_INFORMATION = gql`
  mutation UpdateStudentInformation($input: UpdateStudentInformationInput!) {
    updateStudentInformation(input: $input) {
      ...StudentFragment
    }
  }
  ${studentFragment}
`;
