import {
  PUBLIC_LESSON,
  PUBLIC_ASSESSMENT,
  PUBLIC_TOPIC,
  PUBLIC_UNIT,
  PUBLIC_UNIT_OLD,
} from './public-routes.constants';

export const PRIVATE_CREATE = '/create';
export const PRIVATE_EDIT = '/edit';
export const PRIVATE_CREATE_BY_TYPE = '/create/:type';
export const PRIVATE_CREATE_STIMULUS = `${PRIVATE_CREATE}/stimulus`;
export const PRIVATE_CREATE_STIMULUS_STANDALONE = `${PRIVATE_CREATE_STIMULUS}?type=standalone`;
export const PRIVATE_EDIT_STIMULUS = '/stimulus/:id/edit';
export const PRIVATE_CREATE_STIMULUS_SEARCH = `${PRIVATE_CREATE_STIMULUS}/search`;

export const PRIVATE_SUCCESS = '/success';
export const PRIVATE_ACTIVATE = '/activate';
export const PRIVATE_ADD_TEACHERS = '/add-teachers';
export const PRIVATE_ADD_ADMINS = '/add-admins';
export const PRIVATE_ADD_ADMINS_SUCCESS = `${PRIVATE_ADD_ADMINS}${PRIVATE_SUCCESS}`;
export const PRIVATE_ADMINS = '/admins';
export const PRIVATE_ALLOCATE = '/allocate';
export const PRIVATE_ALLOCATE_SUCCESS = `${PRIVATE_ALLOCATE}${PRIVATE_SUCCESS}`;
export const PRIVATE_BUY = '/buy';
export const PRIVATE_CONFIRM = '/confirm';
export const PRIVATE_CREATE_SCHOOL = '/create-school';
export const PRIVATE_DETAILS = '/details';
export const PRIVATE_FREE = '/free';
export const PRIVATE_GO_WHOLE_SCHOOL = '/go-whole-school';
export const PRIVATE_MEMBERSHIPS = '/memberships';
export const PRIVATE_PAYMENT = '/payment';
export const PRIVATE_PLAN = '/plan';
export const PRIVATE_PURCHASE = '/purchase';
export const PRIVATE_REQUEST_TO_JOIN = '/request-to-join';
export const PRIVATE_SCHOOL = '/school';

export const PRIVATE_CLASS_TO_DO = '/classtodo';

export const PRIVATE_ACCEPT_SCHOOL_INVITE = '/move-school';
export const PRIVATE_ACCEPT_SCHOOL_INVITE_ADMINS = `${PRIVATE_ACCEPT_SCHOOL_INVITE}${PRIVATE_ADMINS}`;
export const PRIVATE_ACCEPT_SCHOOL_INVITE_SUCCESS = `${PRIVATE_ACCEPT_SCHOOL_INVITE}${PRIVATE_SUCCESS}`;

export const PRIVATE_JOIN = '/join';
export const PRIVATE_JOIN_DETAILS = `${PRIVATE_JOIN}${PRIVATE_DETAILS}`;
export const PRIVATE_JOIN_SCHOOL = `${PRIVATE_JOIN}${PRIVATE_SCHOOL}`;
export const PRIVATE_JOIN_PLAN = `${PRIVATE_JOIN}${PRIVATE_PLAN}`;
export const PRIVATE_JOIN_MEMBERSHIPS = `${PRIVATE_JOIN}${PRIVATE_MEMBERSHIPS}`;
export const PRIVATE_JOIN_PAYMENT = `${PRIVATE_JOIN}${PRIVATE_PAYMENT}`;
export const PRIVATE_JOIN_CONFIRM = `${PRIVATE_JOIN}${PRIVATE_CONFIRM}`;
export const PRIVATE_JOIN_SUCCESS = `${PRIVATE_JOIN}${PRIVATE_SUCCESS}`;
export const PRIVATE_JOIN_CREATE_PASSWORD = `${PRIVATE_JOIN}/create-password`;
export const PRIVATE_JOIN_DISPLAY_NAME = `${PRIVATE_JOIN}/display-name`;
export const PRIVATE_JOIN_EMOJI = `${PRIVATE_JOIN}/emoji`;
export const PRIVATE_JOIN_REFER = `${PRIVATE_JOIN}/refer`;
export const PRIVATE_JOIN_THANKS = `${PRIVATE_JOIN}/thank-you`;
export const PRIVATE_JOIN_ADMIN_ROLE = `${PRIVATE_JOIN}/admin-role`;

export const PRIVATE_TRIAL = '/trial';
export const PRIVATE_TRIAL_DETAILS = `${PRIVATE_TRIAL}${PRIVATE_DETAILS}`;
export const PRIVATE_TRIAL_SCHOOL = `${PRIVATE_TRIAL}${PRIVATE_SCHOOL}`;
export const PRIVATE_TRIAL_PLAN = `${PRIVATE_TRIAL}${PRIVATE_PLAN}`;
export const PRIVATE_TRIAL_MEMBERSHIPS = `${PRIVATE_TRIAL}${PRIVATE_MEMBERSHIPS}`;
export const PRIVATE_TRIAL_ASSIGN = `${PRIVATE_TRIAL}/assign`;
export const PRIVATE_TRIAL_CONFIRM = `${PRIVATE_TRIAL}${PRIVATE_CONFIRM}`;
export const PRIVATE_TRIAL_SUCCESS = `${PRIVATE_TRIAL}${PRIVATE_SUCCESS}`;

export const PRIVATE_ACCOUNT = '/account';
export const PRIVATE_ACCOUNT_PROFILE = `${PRIVATE_ACCOUNT}/profile`;
export const PRIVATE_ACCOUNT_BILLING = `${PRIVATE_ACCOUNT}/billing`;
export const PRIVATE_ACCOUNT_REFER = `${PRIVATE_ACCOUNT}/refer`;
export const PRIVATE_ACCOUNT_PEOPLE = `${PRIVATE_ACCOUNT}/people`;
export const PRIVATE_ACCOUNT_VERIFICATION_OLD = `${PRIVATE_ACCOUNT}/verification/:code`;
export const PRIVATE_ACCOUNT_VERIFICATION = `${PRIVATE_ACCOUNT}/verification`;
export const PRIVATE_ACCOUNT_ADD_TEACHER = `${PRIVATE_ACCOUNT}/add-teacher`;
export const PRIVATE_ACCOUNT_ADD_TEACHER_PURCHASE = `${PRIVATE_ACCOUNT_ADD_TEACHER}${PRIVATE_PURCHASE}`;
export const PRIVATE_ACCOUNT_ADD_TEACHER_ADD_ADMINS = `${PRIVATE_ACCOUNT_ADD_TEACHER}${PRIVATE_ADD_ADMINS}`;
export const PRIVATE_ACCOUNT_ADD_TEACHER_ADD_ADMINS_SUCCESS = `${PRIVATE_ACCOUNT_ADD_TEACHER}${PRIVATE_ADD_ADMINS_SUCCESS}`;
export const PRIVATE_ACCOUNT_ADD_TEACHER_ALLOCATE = `${PRIVATE_ACCOUNT_ADD_TEACHER}${PRIVATE_ALLOCATE}`;
export const PRIVATE_ACCOUNT_ADD_TEACHER_ALLOCATE_SUCCESS = `${PRIVATE_ACCOUNT_ADD_TEACHER}${PRIVATE_ALLOCATE_SUCCESS}`;
export const PRIVATE_ACCOUNT_REACTIVATE = `${PRIVATE_ACCOUNT}/reactivate`;
export const PRIVATE_ACCOUNT_REACTIVATE_OLD = `${PRIVATE_ACCOUNT}/account-expired/confirm-school`;
export const PRIVATE_ACCOUNT_REACTIVATE_ACTIVATE = `${PRIVATE_ACCOUNT_REACTIVATE}${PRIVATE_ACTIVATE}`;
export const PRIVATE_ACCOUNT_REACTIVATE_SCHOOL_PLAN = `${PRIVATE_ACCOUNT_REACTIVATE}${PRIVATE_PLAN}`;
export const PRIVATE_ACCOUNT_REACTIVATE_SCHOOL_MEMBERSHIPS = `${PRIVATE_ACCOUNT_REACTIVATE}${PRIVATE_MEMBERSHIPS}`;
export const PRIVATE_ACCOUNT_REACTIVATE_PAYMENT = `${PRIVATE_ACCOUNT_REACTIVATE}${PRIVATE_PAYMENT}`;
export const PRIVATE_ACCOUNT_REACTIVATE_CONFIRM = `${PRIVATE_ACCOUNT_REACTIVATE}${PRIVATE_CONFIRM}`;
export const PRIVATE_ACCOUNT_REACTIVATE_SUCCESS = `${PRIVATE_ACCOUNT_REACTIVATE}${PRIVATE_SUCCESS}`;
export const PRIVATE_ACCOUNT_REACTIVATE_REQUEST_TO_JOIN = `${PRIVATE_ACCOUNT_REACTIVATE}${PRIVATE_REQUEST_TO_JOIN}`;
export const PRIVATE_ACCOUNT_CREATE_SCHOOL = `${PRIVATE_ACCOUNT}${PRIVATE_CREATE_SCHOOL}`;
export const PRIVATE_ACCOUNT_CREATE_SCHOOL_ADD_TEACHERS = `${PRIVATE_ACCOUNT_CREATE_SCHOOL}${PRIVATE_ADD_TEACHERS}`;
export const PRIVATE_ACCOUNT_CREATE_SCHOOL_SUCCESS = `${PRIVATE_ACCOUNT_CREATE_SCHOOL}${PRIVATE_SUCCESS}`;
export const PRIVATE_ACCOUNT_BUY = `${PRIVATE_ACCOUNT}${PRIVATE_BUY}`;
export const PRIVATE_ACCOUNT_BUY_FREE = `${PRIVATE_ACCOUNT_BUY}${PRIVATE_FREE}`;
export const PRIVATE_ACCOUNT_BUY_MEMBERSHIPS = `${PRIVATE_ACCOUNT_BUY}${PRIVATE_MEMBERSHIPS}`;
export const PRIVATE_ACCOUNT_BUY_CONFIRM = `${PRIVATE_ACCOUNT_BUY}${PRIVATE_CONFIRM}`;
export const PRIVATE_ACCOUNT_BUY_SUCCESS = `${PRIVATE_ACCOUNT_BUY}${PRIVATE_SUCCESS}`;
export const PRIVATE_ACCOUNT_RENEWAL = `${PRIVATE_ACCOUNT}/renewal`;
export const PRIVATE_ACCOUNT_RENEWAL_WITH_PARAM = `${PRIVATE_ACCOUNT_RENEWAL}?goWholeSchool=true`;
export const PRIVATE_ACCOUNT_RENEWAL_GO_WHOLE_SCHOOL = `${PRIVATE_ACCOUNT_RENEWAL}${PRIVATE_GO_WHOLE_SCHOOL}`;
export const PRIVATE_ACCOUNT_RENEWAL_PLAN = `${PRIVATE_ACCOUNT_RENEWAL}${PRIVATE_PLAN}`;
export const PRIVATE_ACCOUNT_RENEWAL_MEMBERSHIPS = `${PRIVATE_ACCOUNT_RENEWAL}${PRIVATE_MEMBERSHIPS}`;
export const PRIVATE_ACCOUNT_RENEWAL_CONFIRM = `${PRIVATE_ACCOUNT_RENEWAL}${PRIVATE_CONFIRM}`;
export const PRIVATE_ACCOUNT_RENEWAL_SUCCESS = `${PRIVATE_ACCOUNT_RENEWAL}${PRIVATE_SUCCESS}`;
export const PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION = `${PRIVATE_ACCOUNT}/continue-subscription`;
export const PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION_CREATE_SCHOOL = `${PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION}${PRIVATE_CREATE_SCHOOL}`;
export const PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION_PLAN = `${PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION}${PRIVATE_PLAN}`;
export const PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION_MEMBERSHIPS = `${PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION}${PRIVATE_MEMBERSHIPS}`;
export const PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION_PAYMENT = `${PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION}${PRIVATE_PAYMENT}`;
export const PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION_CONFIRM = `${PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION}${PRIVATE_CONFIRM}`;
export const PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION_SUCCESS = `${PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION}${PRIVATE_SUCCESS}`;
export const PRIVATE_ACCOUNT_PROMO_CODE_QUERY_STRING = '?code=';

export const PRIVATE_ACCOUNT_SCHOOL_JOIN_BASE = `${PRIVATE_ACCOUNT}${PRIVATE_SCHOOL}${PRIVATE_JOIN}`;
export const PRIVATE_ACCOUNT_SCHOOL_JOIN_1 = `${PRIVATE_ACCOUNT_SCHOOL_JOIN_BASE}/:code`;
export const PRIVATE_ACCOUNT_SCHOOL_JOIN_2_SUFFIX = 'step2';
export const PRIVATE_ACCOUNT_SCHOOL_JOIN_2 = `${PRIVATE_ACCOUNT_SCHOOL_JOIN_1}/${PRIVATE_ACCOUNT_SCHOOL_JOIN_2_SUFFIX}`;
export const PRIVATE_ACCOUNT_SCHOOL_ACCESS = `${PRIVATE_ACCOUNT}/school-access-request`;
export const PRIVATE_ACCOUNT_SCHOOL_ACCESS_SUCCESS = `${PRIVATE_ACCOUNT_SCHOOL_ACCESS}${PRIVATE_SUCCESS}`;

export const PRIVATE_ACCOUNT_SCHOOL_SETUP_1 = `${PRIVATE_ACCOUNT}${PRIVATE_SCHOOL}/setup`;
export const PRIVATE_ACCOUNT_SCHOOL_SETUP_2 = `${PRIVATE_ACCOUNT_SCHOOL_SETUP_1}/step2`;

export const PRIVATE_INVITE_OLD = '/invite/:code';
export const PRIVATE_INVITE = '/invite';

export const PRIVATE_CLASS_BASE = '/class';
export const PRIVATE_CLASS = `${PRIVATE_CLASS_BASE}/:classId`;
export const PRIVATE_CLASS_LOGIN = `${PRIVATE_CLASS_BASE}/:classId/login`;
export const PRIVATE_CLASS_TOKEN = `${PRIVATE_CLASS_BASE}/:classId/:token`;
export const PRIVATE_CLASS_LESSON = `${PRIVATE_CLASS_BASE}/lesson/:slug`;
export const PRIVATE_CLASS_ASSESSMENT = `${PRIVATE_CLASS_BASE}/assessment/:slug`;

export const ACCOUNT_TAB_BILLING_PAYMENT_DETAILS_ID = 'payment-details';
export const ACCOUNT_TAB_BILLING_PAYMENT_DETAILS_HASH =
  '#' + ACCOUNT_TAB_BILLING_PAYMENT_DETAILS_ID;
export const ACCOUNT_TAB_BILLING_PAYMENT_DETAILS =
  PRIVATE_ACCOUNT_BILLING + ACCOUNT_TAB_BILLING_PAYMENT_DETAILS_HASH;

export const PRIVATE_PLANNER = '/planner';
export const PRIVATE_PLANNER_RESET_ERROR = '/planner-has-been-reset';
export const PRIVATE_PLANNER_RECEIVE = `${PRIVATE_PLANNER}/receive`;
export const PRIVATE_PLANNER_RECEIVE_CLASS = `${PRIVATE_PLANNER_RECEIVE}/class`;
export const PRIVATE_PLANNER_RECEIVE_SUCCESS = `${PRIVATE_PLANNER_RECEIVE}${PRIVATE_SUCCESS}`;

export const PRIVATE_SSO_OAUTH = '/sso/oauth2';
export const PRIVATE_SSO_PROMOTION = '/sso/promotion';

export const PRIVATE_CLASSES = '/classes';
export const PRIVATE_CLASSES_ARCHIVED = '/classes/archived';
export const PRIVATE_CLASSES_CREATE = '/classes/create';
export const PRIVATE_CLASSES_EDIT = '/classes/:id/edit';

export const PRIVATE_STUDENTS = '/students';
export const PRIVATE_STUDENTS_IMPORT = `${PRIVATE_STUDENTS}/import`;
export const PRIVATE_STUDENT_CLASSES = '/student/classes';

export const PRIVATE_CLASSES_PARAM = '/:userClassId';
export const PRIVATE_LESSON = `${PRIVATE_CLASSES_PARAM}${PUBLIC_LESSON}`;
export const PRIVATE_ASSESSMENT = `${PRIVATE_CLASSES_PARAM}${PUBLIC_ASSESSMENT}`;
export const PRIVATE_UNIT = `${PRIVATE_CLASSES_PARAM}${PUBLIC_UNIT}`;
export const PRIVATE_UNIT_OLD = `${PRIVATE_CLASSES_PARAM}${PUBLIC_UNIT_OLD}`;
export const PRIVATE_TOPIC = `${PRIVATE_CLASSES_PARAM}${PUBLIC_TOPIC}`;

// interactive lessons
export const PRIVATE_INTERACTIVE_LESSON = `${PRIVATE_LESSON}/activities`;
export const PRIVATE_INTERACTIVE_LESSON_CREATE = `${PRIVATE_INTERACTIVE_LESSON}/create`;
export const PRIVATE_INTERACTIVE_LESSON_EDIT = `${PRIVATE_INTERACTIVE_LESSON}/edit`;
export const PRIVATE_INTERACTIVE_LESSON_PUBLIC = `${PRIVATE_INTERACTIVE_LESSON}/public`;
export const PRIVATE_INTERACTIVE_LESSON_PRESENT = `${PRIVATE_INTERACTIVE_LESSON}/present`;
export const PRIVATE_INTERACTIVE_LESSON_STUDENT_PRESENT = `${PRIVATE_CLASS_LESSON}/activities/present`;
export const PRIVATE_INTERACTIVE_LESSON_DRAFT = `${PRIVATE_INTERACTIVE_LESSON}/draft`;

// interactive assessments
export const PRIVATE_INTERACTIVE_ASSESSMENT = `${PRIVATE_ASSESSMENT}/activities`;
export const PRIVATE_INTERACTIVE_ASSESSMENT_CREATE = `${PRIVATE_INTERACTIVE_ASSESSMENT}/create`;
export const PRIVATE_INTERACTIVE_ASSESSMENT_EDIT = `${PRIVATE_INTERACTIVE_ASSESSMENT}/edit`;
export const PRIVATE_INTERACTIVE_ASSESSMENT_PUBLIC = `${PRIVATE_INTERACTIVE_ASSESSMENT}/public`;
export const PRIVATE_INTERACTIVE_ASSESSMENT_PRESENT = `${PRIVATE_INTERACTIVE_ASSESSMENT}/present`;
export const PRIVATE_INTERACTIVE_ASSESSMENT_STUDENT_PRESENT = `${PRIVATE_CLASS_ASSESSMENT}/activities/present`;
export const PRIVATE_INTERACTIVE_ASSESSMENT_DRAFT = `${PRIVATE_INTERACTIVE_ASSESSMENT}/draft`;

export const PRIVATE_ANNOTATIONS = '/annotations';

export const PRIVATE_UPDATE_PASSWORD = '/update-password';
export const PRIVATE_UPDATE_PASSWORD_CONFIRM = `${PRIVATE_UPDATE_PASSWORD}/confirm`;

export const PRIVATE_ASSISTANT = '/assistant';
export const PRIVATE_ASSISTANT_CHAT = `${PRIVATE_CLASSES_PARAM}${PRIVATE_ASSISTANT}/:id`;

export const PRIVATE_SIGNIN_REFRESH_SESSION = '/signin-refresh-session';
