import styled from 'styled-components';

import { rem } from 'approot/shared/style';
import {
  borderRadius16,
  standardFocusState,
} from 'approot/shared/styles/borders.style';
import {
  baseFlexCss,
  flexCentreAlignCss,
} from 'approot/shared/styles/flex.style';
import { grey10, grey2 } from 'approot/shared/styles/colours.style';

export type ColourItem = {
  colour1: string;
  colour2: string;
  colour3: string;
  base: string;
  name?: string;
};

type ColourItemProps = {
  $isActive?: boolean;
  $colours: ColourItem;
  $isSmall?: boolean;
  $size?: number;
};
export const ColourToken = styled.span<ColourItemProps>`
  border-radius: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ $size = 32 }) => rem($size)};
  height: ${({ $size = 32 }) => rem($size)};

  ${({ $colours }) => `
    background-color: ${$colours.colour1};
  `}

  ${({ $isSmall }) =>
    $isSmall &&
    `
    height: ${rem(24)};
    width: ${rem(24)};
  `}
  
  ${({ $colours, $isActive }) =>
    $isActive &&
    `
    &:after {
      content: '';
      background-color: ${$colours.base};
    }
  `}

  &:after {
    content: '';
    border-radius: ${rem(borderRadius16)};
    border: 2px solid ${({ $colours }) => $colours.base};
    width: ${({ $size = 32 }) => rem($size / 2)};
    height: ${({ $size = 32 }) => rem($size / 2)};
  }
`;

export const ColourTokenButton = styled.button<ColourItemProps>`
  ${baseFlexCss}

  background: transparent;
  border: none;
  border-radius: ${rem(20)};
  display: inline-flex;
  justify-content: center;
  width: ${({ $size = 32 }) => rem($size)};
  height: ${({ $size = 32 }) => rem($size)};
  padding: 0;

  ${standardFocusState}

  &:hover {
    cursor: pointer;

    ${ColourToken} {
      ${({ $colours }) => `
        background-color: ${$colours.colour1};
    
        &:hover {
          background-color: ${$colours.colour2};
        }
      `}
    }
  }
`;

type ColourTokenWrapperProps = {
  $size?: number;
};
export const ColourTokenWrapper = styled.div<ColourTokenWrapperProps>`
  ${flexCentreAlignCss}
  display: inline-flex;
  width: ${({ $size = 40 }) => rem($size)};
  height: ${({ $size = 40 }) => rem($size)};
`;

export const ColourTokenItem = styled.span.attrs<
  { 'data-selector'?: string } & ColourItemProps
>({
  'data-selector': 'color-token-item',
})`
  border-radius: ${rem(32)};
  background-color: ${grey2};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${rem(32)};
  width: ${rem(32)};

  ${({ $colours }) => `
    background-color: ${$colours.colour1};

    input:hover + label &&,
    &:hover {
      background-color: ${$colours.colour2};
    }

    input:checked + label && {
      background-color: ${$colours.colour2};
      &:after {
        content: '';
        background-color: ${$colours.base};
      }
    }

    input:focus + label && {
      border: 2px solid ${grey10};
      background-color: ${$colours.colour2};
    }
  `}

  ${({ $colours, $isActive }) =>
    $isActive &&
    `
      background-color: ${$colours.base};
      &:after {
        content: '';
        background-color: ${$colours.base};
      }
  `}

  &:after {
    content: '';
    border-radius: ${rem(16)};
    border: 2px solid ${({ $colours }) => $colours.base};
    width: ${rem(16)};
    height: ${rem(16)};
  }
`;

export const ColourTokenRadio = styled(ColourTokenWrapper)`
  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: 1; // necessary for the hover to work in IE
  }

  label {
    margin: 0;
  }
`;
