import SchoolRoutes from './school/school.routes';
import LazyRoute from 'approot/shared/routes/lazy-route';
import { getRedirectForOldCodeParam } from 'approot/shared/routes/redirects';
import {
  PRIVATE_ACCOUNT,
  PRIVATE_ACCOUNT_VERIFICATION,
  PRIVATE_ACCOUNT_VERIFICATION_OLD,
  PRIVATE_ACCOUNT_ADD_TEACHER,
  PRIVATE_ACCOUNT_CREATE_SCHOOL,
  PRIVATE_ACCOUNT_BUY,
  PRIVATE_ACCOUNT_RENEWAL,
  PRIVATE_ACCOUNT_REACTIVATE,
  PRIVATE_ACCOUNT_REACTIVATE_OLD,
  PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION,
  PRIVATE_ACCOUNT_SCHOOL_ACCESS,
} from 'global/private-routes.constants';
import { PUBLIC_CONFIRM_EMAIL_UPDATE } from 'global/public-routes.constants';
import SchoolSetupRoutes from 'approot/account/school/school-setup.routes';

const AUTHENTICATED_ROUTE = true;

const AccountRoutes = [
  {
    path: PRIVATE_ACCOUNT_VERIFICATION,
    component: LazyRoute(() =>
      import('./account-verification/account-verification')
    ),
  },
  {
    path: PRIVATE_ACCOUNT_VERIFICATION_OLD,
    component: getRedirectForOldCodeParam(PRIVATE_ACCOUNT_VERIFICATION),
  },
  {
    path: `${PRIVATE_ACCOUNT_ADD_TEACHER}/*`,
    component: LazyRoute(
      () => import('./add-teacher/add-teacher'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: `${PRIVATE_ACCOUNT_CREATE_SCHOOL}/*`,
    component: LazyRoute(
      () => import('./create-school/create-school'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: `${PRIVATE_ACCOUNT_REACTIVATE}/*`,
    component: LazyRoute(
      () => import('./reactivate/reactivate'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: PRIVATE_ACCOUNT_REACTIVATE_OLD,
    component: LazyRoute(() => import('./reactivate/redirects')),
    AUTHENTICATED_ROUTE,
  },
  {
    path: PUBLIC_CONFIRM_EMAIL_UPDATE,
    component: LazyRoute(() =>
      import('./email-update-verification/email-update-verification')
    ),
  },
  {
    path: `${PRIVATE_ACCOUNT_BUY}/*`,
    component: LazyRoute(() => import('./buy/buy'), AUTHENTICATED_ROUTE),
  },
  {
    path: `${PRIVATE_ACCOUNT_RENEWAL}/*`,
    component: LazyRoute(
      () => import('./renewal/renewal'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: `${PRIVATE_ACCOUNT_CONTINUE_SUBSCRIPTION}/*`,
    component: LazyRoute(
      () => import('./continue-subscription/continue-subscription'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: `${PRIVATE_ACCOUNT_SCHOOL_ACCESS}/*`,
    component: LazyRoute(
      () => import('./school-access/school-access'),
      AUTHENTICATED_ROUTE
    ),
  },
  ...SchoolRoutes,
  ...SchoolSetupRoutes,
  {
    path: `${PRIVATE_ACCOUNT}/*`,
    component: LazyRoute(() => import('./account'), AUTHENTICATED_ROUTE),
  },
];

export default AccountRoutes;
