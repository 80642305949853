import { useCallback, useEffect, useRef } from 'react';

import { LAYOUT_TRANSITION_DURATION } from 'approot/shared/layout/layout.styles';

export const ESCAPE = 'Escape';
export const ENTER = 'Enter';
export const TAB = 'Tab';
export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_UP = 'ArrowUp';
export const ARROW_RIGHT = 'ArrowRight';
export const ARROW_DOWN = 'ArrowDown';
export const SPACEBAR = ' ';

export function tabbable(element: HTMLElement) {
  let tabIndex: string | number | null = element.getAttribute('tabindex');

  // dropdowns should have data-dropdown-item = true to ignore options
  // in tabbing sequence
  if (element.dataset.dropdownItem === 'true') {
    return false;
  }

  if (tabIndex !== null) {
    return parseInt(tabIndex, 10) >= 0;
  }
  return true;
}

function findFocusableElements(element: HTMLElement | null) {
  if (element) {
    const nodes = element.querySelectorAll(
      'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    );
    return [].slice.call(nodes).filter(tabbable);
  }
  return [];
}

export const useKeyBoardTrap = (options: {
  containerRef: HTMLDivElement | null;
  triggerRef?: HTMLElement | null;
  isActive: boolean;
  initialFocus?: boolean;
  captureKeypress?: boolean;
  onOpenCloseDelay?: number;
  close: () => void;
}) => {
  const {
    containerRef,
    triggerRef,
    isActive,
    initialFocus = true,
    captureKeypress = true,
    onOpenCloseDelay = LAYOUT_TRANSITION_DURATION,
    close,
  } = options;
  const lastFocusedElement = useRef<HTMLElement | null>(null);

  const getFirstEl = useCallback(
    (focusables: HTMLElement[]) => focusables?.[0],
    []
  );

  const getLastEl = useCallback((focusables: HTMLElement[]) => {
    return focusables.length > 0 ? focusables[focusables.length - 1] : null;
  }, []);

  const onBackwardTab = useCallback(
    (e: KeyboardEvent, focusables: HTMLElement[]) => {
      if (document.activeElement === getFirstEl(focusables)) {
        e.preventDefault();
        const el = getLastEl(focusables);
        if (el) el.focus();
      }
    },
    [getFirstEl, getLastEl]
  );

  const onForwardTab = useCallback(
    (e: KeyboardEvent, focusables: HTMLElement[]) => {
      const fIndex = focusables.findIndex(
        (f: HTMLElement) => f === document.activeElement
      );
      // handle case where radios are the last focusable items in a drawer
      if (
        (focusables[fIndex] as HTMLInputElement | undefined)?.type === 'radio'
      ) {
        for (let i = fIndex; i < focusables.length; i++) {
          if (
            (focusables[i] as HTMLInputElement).name ===
              (focusables[fIndex] as HTMLInputElement).name &&
            i === focusables.length - 1
          ) {
            e.preventDefault();
            getFirstEl(focusables)?.focus();
            break;
          }
        }
      } else if (document.activeElement === getLastEl(focusables)) {
        e.preventDefault();
        getFirstEl(focusables)?.focus();
      }
    },
    [getFirstEl, getLastEl]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case TAB:
          if (containerRef) {
            const focusables = findFocusableElements(containerRef);
            if (focusables.length === 1) {
              e.preventDefault();
              (focusables[0] as HTMLElement).focus();
              break;
            }
            if (e.shiftKey) {
              onBackwardTab(e, focusables);
            } else {
              onForwardTab(e, focusables);
            }
          }
          break;
        case ESCAPE:
          e.stopPropagation();
          e.preventDefault();
          close();
          break;
        default:
          break;
      }
    },
    [containerRef, onBackwardTab, onForwardTab, close]
  );

  useEffect(() => {
    if (isActive && containerRef) {
      setTimeout(() => {
        if (isActive && containerRef) {
          lastFocusedElement.current = document.activeElement as HTMLElement;
          if (initialFocus) containerRef.focus();
        }
      }, onOpenCloseDelay);
    } else if (!isActive) {
      if (triggerRef) {
        triggerRef.focus();
      } else if (lastFocusedElement.current) {
        lastFocusedElement.current.focus();
        lastFocusedElement.current = null;
        setTimeout(() => {
          if (containerRef) {
            containerRef.scrollTop = 0;
          }
        }, onOpenCloseDelay);
      }
    }
  }, [isActive, initialFocus, containerRef, triggerRef, onOpenCloseDelay]);

  useEffect(() => {
    const containerRefCopy = containerRef;

    if (containerRefCopy && captureKeypress) {
      containerRefCopy.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      // clean up listeners
      if (containerRefCopy && captureKeypress) {
        containerRefCopy.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyDown, containerRef, captureKeypress]);
};

export function findDropdownButtons(
  element: HTMLElement | null
): HTMLButtonElement[] | [] {
  if (element) {
    const nodes = element.querySelectorAll<HTMLButtonElement>(
      '[data-dropdown-item="true"]:not([disabled])'
    );
    return Array.from(nodes);
  }
  return [];
}

function goToNextDropdownButton(buttons: HTMLButtonElement[]) {
  if (buttons.length > 1) {
    const currentIndex = buttons.findIndex(f => f === document.activeElement);

    if (currentIndex !== -1 && currentIndex < buttons.length - 1) {
      (buttons[currentIndex + 1] as HTMLElement).focus();
    }
  }
}

function goToPreviousDropdownButton(buttons: HTMLButtonElement[]) {
  if (buttons.length > 1) {
    const currentIndex = buttons.findIndex(f => f === document.activeElement);

    if (currentIndex > 0) {
      (buttons[currentIndex - 1] as HTMLElement).focus();
    }
  }
}

// each dropdown item must have data-dropdown-item="true"
export const useDropdownTrap = (
  dropdownRef: HTMLDivElement | null,
  dropdownTriggerRef: HTMLButtonElement | null,
  handleCloseDropdown: () => void
) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!dropdownRef) return;

      const buttons = findDropdownButtons(dropdownRef);

      switch (e.key) {
        case ARROW_UP:
          e.preventDefault();
          goToPreviousDropdownButton(buttons);

          break;
        case ARROW_DOWN:
          e.preventDefault();
          goToNextDropdownButton(buttons);

          break;
        case TAB:
          e.preventDefault();

          if (e.shiftKey) {
            goToPreviousDropdownButton(buttons);
          } else {
            goToNextDropdownButton(buttons);
          }
          break;
        case ESCAPE:
          e.preventDefault();
          e.stopPropagation();
          handleCloseDropdown();
          dropdownTriggerRef?.focus();
          break;
        default:
          break;
      }
    },
    [dropdownRef, handleCloseDropdown, dropdownTriggerRef]
  );

  useEffect(() => {
    if (dropdownRef) {
      dropdownRef.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      // clean up listeners
      if (dropdownRef) {
        dropdownRef.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyDown, dropdownRef]);
};

// the element that triggers a dropdown must data-dropdown-container-id="<id>"
export const useDropdownTrigger = (triggerRef: HTMLButtonElement | null) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!triggerRef) return;

      const currentElement = document.activeElement as HTMLElement | null;
      const isDropdownTrigger = currentElement === triggerRef;

      switch (e.key) {
        case ENTER:
        case ARROW_DOWN:
        case SPACEBAR:
          if (isDropdownTrigger) {
            e.preventDefault();
            currentElement.click();

            const dropdownContainerId =
              currentElement.dataset.dropdownContainerId;
            const dropdownContainer =
              dropdownContainerId &&
              document.getElementById(dropdownContainerId);
            const dropdownContents =
              dropdownContainer && findDropdownButtons(dropdownContainer);

            if (dropdownContents && dropdownContents.length > 0) {
              dropdownContents[0].focus();
            }
          }
          break;
        default:
          break;
      }
    },
    [triggerRef]
  );

  useEffect(() => {
    if (triggerRef) {
      triggerRef.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      // clean up listeners
      if (triggerRef) {
        triggerRef.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyDown, triggerRef]);
};
