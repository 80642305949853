import { useEffect } from 'react';
import {
  LDSingleKindContext,
  useLDClient,
} from 'launchdarkly-react-client-sdk';

import useUserData from 'approot/shared/user/user-data';
import { GetUser_user } from 'approot/shared/user/__generated__/GetUser';
import { getDataTrackerClientId } from 'approot/shared/data-tracker/get-data-tracker-client-id';

export const GUEST_KEY = 'guest';
export const USER_KIND = 'user';

export function initialiseLdUser(
  user?: GetUser_user | null
): LDSingleKindContext {
  const flags = user?.featureFlags;
  if (flags) {
    return {
      kind: USER_KIND, // this is the default value if not set
      key: user?.id.toString(),
      groups: flags.groups,
    };
  }

  return {
    kind: USER_KIND, // this is the default value if not set
    key: getDataTrackerClientId() || GUEST_KEY,
  };
}

const GlobalLaunchDarkly = () => {
  const data = useUserData();
  const ldClient = useLDClient();

  const isSignedIn = data.isSignedIn;
  const user = data.user;

  useEffect(() => {
    if (ldClient) {
      const ldUser = ldClient.getContext();

      if (ldUser) {
        const newUser = initialiseLdUser(user);
        if (ldUser.key !== newUser.key) {
          console.log('changing ld user to', newUser);

          ldClient.identify({
            ...newUser,
          });
        }
      }
    }
  }, [isSignedIn, ldClient, user]);

  return <></>;
};

export default GlobalLaunchDarkly;
