import { TypePolicies } from '@apollo/client';

export function override(existing: any, incoming: any) {
  // a custom function is required to silence the warning: 'Cache data may be lost when replacing...'
  return incoming;
}

export const interactiveLessonTypePolicies: TypePolicies = {
  ActivitiesSection: {
    fields: {
      components: {
        merge: override,
      },
      subSections: {
        merge: override,
      },
    },
  },
  LessonActivities: {
    fields: {
      sections: {
        merge: override,
      },
      sectionsOverview: {
        merge: override,
      },
      teachingAndLearningSequence: {
        merge: override,
      },
      styles: {
        merge(existing, incoming) {
          return {
            ...(existing || {}),
            ...(incoming || {}),
          };
        },
      },
    },
  },
  LessonMaterial: {
    fields: {
      alternativeMaterials: {
        merge: override,
      },
    },
  },
  AssessmentActivities: {
    fields: {
      sections: {
        merge: override,
      },
      sectionsOverview: {
        merge: override,
      },
      teachingAndLearningSequence: {
        merge: override,
      },
      styles: {
        merge(existing, incoming) {
          return {
            ...(existing || {}),
            ...(incoming || {}),
          };
        },
      },
    },
  },
  ActivitiesActivityComponent: {
    fields: {
      properties: {
        merge(existing, incoming) {
          return {
            ...(existing || {}),
            ...(incoming || {}),
          };
        },
      },
      subActivities: {
        merge: override,
      },
    },
  },
  ActivitiesTeachingLearningSequenceItem: {
    fields: {
      component: {
        merge: override,
      },
      lessonMaterials: {
        merge: override,
      },
      connections: {
        merge: override,
      },
      theEsDetails: {
        merge: override,
      },
      visibleThinkingActivitiesDetails: {
        merge: override,
      },
      highImpactTeachingStrategiesDetails: {
        merge: override,
      },
    },
  },
  ActivitiesSlideshowComponent: {
    fields: {
      slideshowImages: {
        merge: override,
      },
    },
  },
  ActivitiesColumnComponent: {
    keyFields: ['columnId'],
    fields: {
      components: {
        merge: override,
      },
    },
  },
  ActivitiesColumnsComponent: {
    fields: {
      columns: {
        merge: override,
      },
    },
  },
  ActivitiesImageComponent: {
    fields: {
      stimulusResourceProperties: {
        merge: override,
      },
    },
  },
  ActivitiesSectionOverview: {
    fields: {
      subSectionsOverview: {
        merge: override,
      },
    },
  },
  ActivityAnswer: {
    keyFields: ['uuid'],
    fields: {
      answer: {
        merge: override,
      },
      answerReferences: {
        merge: override,
      },
    },
  },
  ActivitiesVocabularyComponent: {
    fields: {
      vocabularyDefinitions: {
        merge: override,
      },
    },
  },
};

export const interactiveLessonQueryTypePolicies: TypePolicies = {
  getLessonTeachingAndLearningSequence: {
    merge: override,
  },
  getAssessmentTeachingAndLearningSequence: {
    merge: override,
  },
};
