import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import {
  grey1,
  grey2,
  grey6,
  purple6,
  purple7,
  purple8,
} from 'approot/shared/styles/colours.style';
import {
  ButtonBaseStyled,
  ButtonBaseProps,
  Variant,
} from 'approot/shared/styles/buttons/base-button.style';
import { rem } from 'approot/shared/style';

type ButtonProps = ButtonBaseProps & {
  $variant?: Variant;
  $loading?: boolean;
  $borderRadius?: number;
};

export const PrimaryButton = styled.button<ButtonProps>`
  ${ButtonBaseStyled}

  ${({ $variant }) =>
    $variant
      ? css`
          ${$variant === 'outlined'
            ? PrimaryButtonOutlineColours
            : PrimaryButtonColours}
          ${$variant === 'link' &&
            `
        display: inline-flex;
        align-items: center;
        justify-content: center;
        `}
        `
      : PrimaryButtonColours}
`;

export const PrimaryButtonColours = css`
  background-color: ${purple7};
  border: 2px solid ${purple7};
  color: ${grey1};
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: ${purple6};
    border-color: ${purple6};
    color: ${grey1};
  }

  &:focus {
    border-color: ${grey1};
  }

  &:active {
    background-color: ${purple8};
    border-color: ${grey1};
    color: ${grey1};
  }
`;

export const PrimaryButtonLink = styled(Link)<ButtonBaseProps>`
  ${ButtonBaseStyled}
  ${PrimaryButtonColours}

  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const PrimaryButtonOutlineColours = css`
  border-color: ${purple7};
  color: ${purple7};
  background: transparent;

  &:hover {
    background-color: ${grey2};
    border-color: ${purple6};
    color: ${purple6};
  }

  &:focus {
    border-color: ${grey1};
    color: ${purple7};
  }

  &:active {
    border-color: ${purple8};
  }
`;

export type PrimaryButtonIconProps = {
  $borderRadius?: number;
};
export const PrimaryButtonIcon = styled(PrimaryButton)<PrimaryButtonIconProps>`
  min-width: ${({ $isSmall }) => ($isSmall ? rem(32) : rem(40))};
  padding-left: 0;
  padding-right: 0;

  ${({ $borderRadius }) =>
    $borderRadius !== undefined &&
    `
    border-radius: ${rem($borderRadius)};
  `}

  svg path {
    stroke: ${grey1};
  }

  &:disabled {
    svg path {
      stroke: ${grey6};
    }
  }
`;
