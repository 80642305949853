import { UserFragment } from 'approot/shared/user/__generated__/UserFragment';
import { RememberType } from 'approot/shared/signin/signin.constants';

// Please update `udk` (user data key) in index.html if you need to change this
export const USER_DATA_KEY = `userData:${process.env.REACT_APP_REGION}`;
export const SKIP_PATH_DATA_KEY = 'skipPath';
export const UPDATE_META_DATA = 'AUTH_UPDATE_META';
export const ALREADY_SIGNED_IN_KEY = `alreadySignedIn:${process.env.REACT_APP_REGION}`;
export const AUTH_HTTP_SOURCE_FILE = 'auth.http.tsx';

export type StorageUserData = {
  token: string;
  user: UserFragment;
  remember?: RememberType;
  skipChooseClassRedirectionPath?: string;
};
